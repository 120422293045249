class ManagerRoles {
    static AGENCY = "agency";
    static DEVELOPER = "developer";

    getRoles() {
        return [ManagerRoles.AGENCY, ManagerRoles.DEVELOPER];
    }
}

export default ManagerRoles;
