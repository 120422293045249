<template>
  <div class="project-update-lawyers-allocation">
    <div class="card mb-1">
      <h1 class="d-flex justify-content-between align-items-center p-3">
        Allocate Lawyers
        <fd-button @click="toProject">
          <i class="fas fa-list-ul"></i>
        </fd-button>
      </h1>
    </div>

    <project-detail-header :project="project"></project-detail-header>

    <fd-form class="card p-1 sm-p-3" @submit="updateLawyerAllocation">
      <assign-lawyers
        ref="assignLawyers"
        isEdit
        v-model="lawyers"
        required
        :type="type"
        :projectId="projectId"
        :mapValue="(val) => val.id"
        @update="(val) => (newLawyers = val)"
      ></assign-lawyers>
      <fd-button
        type="submit"
        class="main medium fixed round"
        :disabled="newLawyers.length < 1 || isUpdatingLawyer"
        loadingEnabled
        :isLoading="isUpdatingLawyer"
        icon="fas fa-edit"
      >
        Update
      </fd-button>
    </fd-form>
  </div>
</template>

<script>
import ProjectAPI from "@/modules/Project/api/project";

export default {
  components: {
    AssignLawyers: () =>
      import("@/modules/Project/components/AssignLawyers/AssignToProject"),
    ProjectDetailHeader: () =>
      import("@/modules/Project/components/ProjectDetail/Header")
  },
  mixins: [],
  props: {
    projectId: {
      type: [String, Number],
      required: true
    },
    type: {
      type: String,
      validator: (val) => ["agency", "developer"].includes(val)
    }
  },
  data: function () {
    return {
      project: {},
      lawyers: [],
      newLawyers: [],
      projectAPI: new ProjectAPI(this.type),

      isUpdatingLawyer: false
    };
  },
  computed: {},
  watch: {},
  created: function () {},
  beforeDestroy: function () {},
  mounted: function () {
    this.init();
  },
  methods: {
    init() {
      this.getProject();
    },
    toProject() {
      this.$router.push({
        name: "ManageProjects"
      });
    },
    async getProject() {
      try {
        let data = await this.projectAPI.getProject(this.projectId);
        this.project = this._.cloneDeep(data);
      } catch (error) {
        this.$notify({
          group: "alert",
          type: "error",
          title: "Error",
          text: "Failed to fetch project details. Please try again later."
        });
      }
    },
    async updateLawyerAllocation() {
      try {
        this.isUpdatingLawyer = true;

        await this.projectAPI.addAssignedLawyers(this.projectId, {
          lawyerIds: this.newLawyers
        });

        await this.$refs["assignLawyers"].reloadAssignedLawyers();

        this.$notify({
          group: "alert",
          type: "success",
          title: "Success",
          text: "Lawyer's allocation has been updated successfully."
        });
        this.isUpdatingLawyer = false;
      } catch (error) {
        this.isUpdatingLawyer = false;
        this.$notify({
          group: "alert",
          type: "error",
          title: "Error",
          text: "Failed to update lawyer's allocation. Please try again later."
        });
      }
    }
  }
};
</script>

<style lang="scss"></style>
