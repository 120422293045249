import axios from "axios";
import { reportError } from "@/utils/error-reporting";
import API from "@/modules/Project/classes/API";

class ProjectAPI extends API {
    async getProjects({ queries = {}, page = 1, perPage = 15 }) {
        try {
            let include = "";

            let response = await axios.get(`${ProjectAPI.apiURL}/projects`, {
                params: {
                    ...queries,
                    include: include,
                    page: page,
                    limit: perPage
                }
            });
            return response.data;
        } catch (error) {
            reportError(error, `[${ProjectAPI.panelName}] Get Projects`);
            throw error;
        }
    }
    async getProject(id) {
        let include =
            "projectFacilities,projectAmenities,projectTags,projectMarketingStaffs";

        try {
            let response = await axios.get(
                `${ProjectAPI.apiURL}/projects/${id}?include=${include}`
            );
            return response.data;
        } catch (error) {
            reportError(
                error,
                `[${ProjectAPI.panelName}] Get Project (#${id})`
            );
            throw error;
        }
    }

    async getUsedPlots(id) {
        try {
            let response = await axios.get(
                `${ProjectAPI.apiURL}/projects/${id}/project-plot`
            );
            return response.data;
        } catch (error) {
            reportError(
                error,
                `[${ProjectAPI.panelName}] Get Used Plot (Project #${id})`
            );
            throw error;
        }
    }
    async createProject(payload) {
        try {
            let response = await axios.post(
                `${ProjectAPI.apiURL}/projects`,
                payload
            );

            return response.data;
        } catch (error) {
            reportError(error, `[${ProjectAPI.panelName}] Create Project`);
            throw error;
        }
    }
    async updateProject({ id, payload }) {
        try {
            let response = await axios.put(
                `${ProjectAPI.apiURL}/projects/${id}`,
                payload
            );

            return response.data;
        } catch (error) {
            reportError(error, `[${ProjectAPI.panelName}] Update Project`);
            throw error;
        }
    }
    async updateStatus({ id, payload }) {
        try {
            let response = await axios.put(
                `${ProjectAPI.apiURL}/projects/${id}/status`,
                payload
            );

            return response.data;
        } catch (error) {
            reportError(error, `[${ProjectAPI.panelName}] Update Status`);
            throw error;
        }
    }
    async deleteProject(id) {
        try {
            const response = await axios.delete(
                `${ProjectAPI.apiURL}/projects/${id}`
            );

            return response;
        } catch (error) {
            reportError(error, `[${ProjectAPI.panelName}] Delete Project`);
            throw error;
        }
    }

    async getMarketingStaffs({ queries = {}, page = 1, perPage = 30 }) {
        try {
            const response = await axios.get(
                `${ProjectAPI.apiURL}/project-marketing-staffs`,
                {
                    params: { ...queries, page: page, limit: perPage }
                }
            );

            return response.data;
        } catch (error) {
            reportError(error, `[${ProjectAPI.panelName}] Get Marketing Staff`);
            throw error;
        }
    }

    // ================================== BANKERS ==================================
    async getBankers({ queries = {}, page = 1, perPage = 15 }) {
        let URL = `${ProjectAPI.apiURL}/bankers`;
        try {
            const response = await axios.get(URL, {
                params: {
                    ...queries,
                    page: page,
                    limit: perPage
                }
            });
            return response.data;
        } catch (error) {
            reportError(error, `[${ProjectAPI.panelName}] Get Bankers`);
            throw error;
        }
    }
    async getUnassignedBankers(
        projectId,
        { queries = {}, page = 1, perPage = 15 }
    ) {
        let URL = `${ProjectAPI.apiURL}/projects/${projectId}/available-banker`;
        try {
            const response = await axios.get(URL, {
                params: {
                    ...queries,
                    page: page,
                    limit: perPage
                }
            });
            return response.data;
        } catch (error) {
            reportError(
                error,
                `[${ProjectAPI.panelName}] Get Unassigned Bankers (Project #${projectId})`
            );
            throw error;
        }
    }
    async getAssignedBankers(
        projectId,
        { queries = {}, page = 1, perPage = 15 }
    ) {
        let URL = `${ProjectAPI.apiURL}/projects/${projectId}/assigned-banker`;
        try {
            const response = await axios.get(URL, {
                params: {
                    ...queries,
                    page: page,
                    limit: perPage
                }
            });
            return response.data;
        } catch (error) {
            reportError(
                error,
                `[${ProjectAPI.panelName}] Get Assigned Bankers (Project #${projectId})`
            );
            throw error;
        }
    }
    async addAssignedBankers(projectId, payload) {
        let URL = `${ProjectAPI.apiURL}/projects/${projectId}/add-banker`;
        try {
            const response = await axios.post(URL, payload);
            return response.data;
        } catch (error) {
            reportError(
                error,
                `[${ProjectAPI.panelName}] Update Assigned Bankers (Project #${projectId})`
            );
            throw error;
        }
    }
    async removeAssignedBanker(projectId, payload) {
        let URL = `${ProjectAPI.apiURL}/projects/${projectId}/remove-banker`;
        try {
            const response = await axios.delete(URL, { data: payload });
            return response.data;
        } catch (error) {
            reportError(
                error,
                `[${ProjectAPI.panelName}] Remove Assigned Bankers (Project #${projectId})`
            );
            throw error;
        }
    }

    // ================================== LAWYERS ==================================
    async getLawyers({ queries = {}, page = 1, perPage = 15 }) {
        let URL = `${ProjectAPI.apiURL}/lawyers`;
        try {
            const response = await axios.get(URL, {
                params: {
                    ...queries,
                    page: page,
                    limit: perPage
                }
            });
            return response.data;
        } catch (error) {
            reportError(error, `[${ProjectAPI.panelName}] Get Lawyers`);
            throw error;
        }
    }
    async getUnassignedLawyers(
        projectId,
        { queries = {}, page = 1, perPage = 15 }
    ) {
        let URL = `${ProjectAPI.apiURL}/projects/${projectId}/available-lawyer`;
        try {
            const response = await axios.get(URL, {
                params: {
                    ...queries,
                    page: page,
                    limit: perPage
                }
            });
            return response.data;
        } catch (error) {
            reportError(
                error,
                `[${ProjectAPI.panelName}] Get Unassigned Lawyers (Project #${projectId})`
            );
            throw error;
        }
    }
    async getAssignedLawyers(
        projectId,
        { queries = {}, page = 1, perPage = 15 }
    ) {
        let URL = `${ProjectAPI.apiURL}/projects/${projectId}/assigned-lawyer`;
        try {
            const response = await axios.get(URL, {
                params: {
                    ...queries,
                    page: page,
                    limit: perPage
                }
            });
            return response.data;
        } catch (error) {
            reportError(
                error,
                `[${ProjectAPI.panelName}] Get Assigned Lawyers (Project #${projectId})`
            );
            throw error;
        }
    }
    async addAssignedLawyers(projectId, payload) {
        let URL = `${ProjectAPI.apiURL}/projects/${projectId}/add-lawyer`;
        try {
            const response = await axios.post(URL, payload);
            return response.data;
        } catch (error) {
            reportError(
                error,
                `[${ProjectAPI.panelName}] Add Assigned Lawyers (Project #${projectId})`
            );
            throw error;
        }
    }
    async removeAssignedLawyer(projectId, payload) {
        let URL = `${ProjectAPI.apiURL}/projects/${projectId}/remove-lawyer`;
        try {
            const response = await axios.delete(URL, { data: payload });
            return response.data;
        } catch (error) {
            reportError(
                error,
                `[${ProjectAPI.panelName}] Remove Assigned Lawyers (Project #${projectId})`
            );
            throw error;
        }
    }
}

export default ProjectAPI;
