import ManagerRoles from "@/modules/Project/classes/ManagerRoles";
import { agencyAPI, developerAPI } from "@/modules/api-config";

class API {
    apiURL;
    panelName;

    constructor(role) {
        if (role === ManagerRoles.AGENCY) {
            API.apiURL = agencyAPI;
            API.panelName = "Agency CP";
        } else if (role === ManagerRoles.DEVELOPER) {
            API.apiURL = developerAPI;
            API.panelName = "Developer CP";
        }
    }
}

export default API;
